import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import { formatDatoCMSData } from '../services/datoCMS.service'
import { locationData } from '../assets/locationData.js'

export default function Events() {
  const currentPage = "events";
  const title = 'Events';

  const query = useStaticQuery(graphql`
  query publicEventsQuery {
    allDatoCmsPublicEvent(sort: {fields: position, order: ASC}) {
    nodes {
      title
      date
      admission
      description
      location {
        bryant
        downtown
      }
    }
  }
  }`);

  const eventsData = formatDatoCMSData(query);

  // Our events are built from our regular menu items
  const events = eventsData.nodes.map((event) => {
    const locations = event.locations.map((location) => locationData.getLocationData(location)?.name ?? null);
    const locationList = locations.length ? locations.join(', ') : 'TBD';

    return (
      <div className="row" key={event.title}>
        <div className="col-8 col-12-xsmall">
          <h3>{event.title}</h3>
          {event.description &&
            <p>{event.description}</p>}
          {event.date &&
            <p><strong>Date:</strong> {event.date}</p>}
          {event.admission &&
            <p><strong>Admission:</strong> {event.admission}</p>}
          {locationList &&
            <p><strong>Location:</strong> {locationList}</p>}
        </div>
      </div>
    );
  });

  const noevents = (
    <div className="row">
      <div className="col-8 col-12-xsmall">
        <h3>No Upcoming Events</h3>
          <p>Check back soon for updates!</p>
      </div>
    </div>
  );

  return (
    <Layout className="menuPage" location="events">
      <EllisHelmet page={currentPage} title={title} location={null} />
      <div id="eventspage" className="wrapper style1">
        <div className="container">
          <header className="major">
            <h2>Events</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-12 col-12-xsmall">
                {events.length > 0 && events}
                {!events.length && noevents}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
}
